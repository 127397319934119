export const loaderFetch = async (remote: string, brand: string): Promise<void> => {
  await fetch(`${remote}/manifest.json`)
    .then((response) => response.json())
    .then((manifest) => {
      document.head.insertAdjacentHTML('beforeend', `<link rel=stylesheet href="${remote}/${brand}/main.${manifest.version}.css">`);

      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `${remote}/${brand}/main.${manifest.version}.js`;
      document.head.appendChild(s);
    });
};
