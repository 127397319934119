import { loaderFetch } from './loader-fetch';

(() => {
  const brand = document?.currentScript?.getAttribute('data-product');

  if (!brand) throw new Error('Brand not provided');

  if (!REMOTE) throw new Error('Remote not provided');

  loaderFetch(REMOTE, brand);
})();
